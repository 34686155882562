var $ = require('jquery');
var cookieService = require('./cookies.js');
require('slick-carousel');

$(document).on('ready', function() {
    var $serviceSlider = $('.service-image.has-slider');
    var $projectSlider = $('.project-slider');

    if ($serviceSlider.length > 0) {
        $serviceSlider.slick();
    }

    if ($projectSlider.length > 0) {
        $projectSlider.slick();
    }

    if (!cookieService.getCookie('cookiesAccepted')) {
        $('.cookies').show();
    }

    $('.cookie-close').on('click', function() {
        cookieService.setCookie('cookiesAccepted', true, 365);
        $('.cookies').hide();
    });
});